import React, { useState } from "react"
import styles from "../styles/global.module.scss"

export default function ContactForm({ selectedCta }) {
  // Initialize local state
  let [localState, setLocalState] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  })

  // Update state on form field change
  const onChange = e => {
    setLocalState({
      ...localState,
      [e.target.name]: e.target.value,
    })
  }

  return (
    <form
      className={styles.primaryForm}
      name="Contact"
      method="POST"
      netlify-honeypot="bot-field"
      data-netlify="true"
      action="/thank-you/"
    >
      <input type="hidden" name="form-name" value="Contact" />
      <input type="hidden" name="bot-field" />
      <div className={styles.formItem}>
        <label className={styles.hidden} htmlFor="your-name">
          Your Name
        </label>
        <input
          id="your-name"
          type="text"
          name="name"
          placeholder="Your Name *"
          onBlur={e => onChange(e)}
          onChange={e => onChange(e)}
          required
        />
      </div>
      <div className={styles.formItem}>
        <label className={styles.hidden} htmlFor="phone">
          Phone Number
        </label>
        <input
          id="phone"
          type="phone"
          name="phone"
          placeholder="Phone Number *"
          onBlur={e => onChange(e)}
          onChange={e => onChange(e)}
          required
        />
      </div>
      <div className={styles.formItem}>
        <label className={styles.hidden} htmlFor="email">
          Your Email
        </label>
        <input
          id="email"
          type="email"
          name="email"
          placeholder="Your Email *"
          onBlur={e => onChange(e)}
          onChange={e => onChange(e)}
          required
        />
      </div>
      <div className={styles.formItem}>
        <label className={styles.hidden} htmlFor="message">
          What can we help you with?
        </label>
        <textarea
          id="message"
          name="message"
          placeholder="What can we help you with? *"
          rows="4"
          cols="50"
          onBlur={e => onChange(e)}
          onChange={e => onChange(e)}
          required
        />
      </div>
      <input type="hidden" name="selectedCTA" value={selectedCta} />
      <div className={styles.btnContain}>
        <button className={styles.btn} type="submit" aria-label="Send">
          Send
        </button>
      </div>
    </form>
  )
}
