// Libraries
import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import styles from "@one-builder/gatsby-theme-ob-master/src/styles/global.module.scss"

// Components (Updated to scoped path)
import Layout from "../components/layout/layout"
import ContactForm from "../components/contactform"
import MarkdownContent from "@one-builder/gatsby-theme-ob-master/src/components/content"
import { useGetTokens } from "@one-builder/gatsby-theme-ob-master/src/hooks/useGetTokens"
import convertTokens from "@one-builder/gatsby-theme-ob-master/src/helpers/convertTokens"
import TestimonialSlider from "../components/home/testimonial-slider"
import AnimatedGraphics from "../components/AnimatedGraphics"
// import houseIcon from "../../../../static/images/house-icon.svg"
import setSeoData from "@one-builder/gatsby-theme-ob-master/src/helpers/setSeoData"

export default ({ data }) => {
  // Get metadata
  const metadata = useGetTokens()

  // Get Home Page Data
  const { home } = data

  let seo_data = setSeoData(home.frontmatter)

  // Send CTA data to form
  let [selectedCta, setSelectedCta] = useState("")
  useEffect(() => {
    const hp_btns = document.querySelectorAll(".btn-contain a")
    if (hp_btns) {
      for (let btn of hp_btns) {
        btn.addEventListener("click", () => {
          setSelectedCta(btn.title)
        })
      }
    }
  }, [selectedCta])

  // Smooth Scroll
  useEffect(() => {
    document.addEventListener("scroll", handleScroll)
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener("click", function(e) {
        e.preventDefault()

        document.querySelector(this.getAttribute("href")).scrollIntoView({
          behavior: "smooth",
        })
      })
    })

    // Button hover effects
    const row1_btns = document.querySelectorAll(".row1-btns")
    const row1_section = document.getElementById("row1-section")
    const row4_btns = document.querySelectorAll(".row4-btns")
    const row4_section = document.getElementById("row4-section")
    const row_is_active = ele => {
      ele.classList.add("row-active")
    }
    const row_is_inactive = ele => {
      ele.classList.remove("row-active")
    }
    if (row1_btns && row1_section) {
      for (let btn of row1_btns) {
        btn.addEventListener("mouseenter", () => {
          row_is_active(row1_section)
        })
        btn.addEventListener("mouseleave", () => {
          row_is_inactive(row1_section)
        })
      }
    }
    if (row4_btns && row4_section) {
      for (let btn of row4_btns) {
        btn.addEventListener("mouseenter", () => {
          row_is_active(row4_section)
        })
        btn.addEventListener("mouseleave", () => {
          row_is_inactive(row4_section)
        })
      }
    }
  }, [])

  // Animate arrow and content on scroll
  const handleScroll = () => {
    const row2_targ = document.querySelector("#row2-targ")
    const row3 = document.querySelector("#row3-targ")
    const feature_section_1 = document.querySelector("#feature-section-1")
    const feature_section_2 = document.querySelector("#feature-section-2")
    const feature_section_3 = document.querySelector("#feature-section-3")
    // page distance from top
    let scrolled = document.scrollingElement.scrollTop
    if (row2_targ && row3) {
      // element distance from top of page
      let row2_offset = row2_targ.offsetTop
      let feature_section_1_offset = feature_section_1.offsetTop
      let feature_section_2_offset = feature_section_2.offsetTop
      let activate_animation = 0
      if (scrolled >= row2_offset - activate_animation) {
        row3.classList.add("feature-section-active")
        feature_section_1.classList.add("feature-section-active")
      } else {
        row3.classList.remove("feature-section-active")
        feature_section_1.classList.remove("feature-section-active")
      }
      if (scrolled >= feature_section_1_offset - activate_animation) {
        feature_section_2.classList.add("feature-section-active")
      } else {
        feature_section_2.classList.remove("feature-section-active")
      }
      if (scrolled >= feature_section_2_offset - activate_animation) {
        feature_section_3.classList.add("feature-section-active")
      } else {
        feature_section_3.classList.remove("feature-section-active")
      }
    } else {
      if (row3) {
        row3.classList.add("feature-section-active")
        feature_section_1.classList.add("feature-section-active")
        feature_section_2.classList.add("feature-section-active")
        feature_section_3.classList.add("feature-section-active")
      }
    }
  }

  return (
    <Layout seo_data={seo_data}>
      <section id="row1-section" className={`${styles.row} ${styles.row1}`}>
        <div className={styles.container}>
          <MarkdownContent
            className={`homecontent`}
            content={convertTokens(home.frontmatter.welcomeSection, metadata)}
          />
          <a id={styles.houseIconSmall} className="row1-btns" href="#row2-targ">
            <img src="/images/house-icon-small.png" alt="House Icon" />
          </a>
        </div>
      </section>

      <section id="row2-targ" className={`${styles.row} ${styles.row2}`}>
        <div className={styles.container}>
          <div className={styles.flexWrapper}>
            <div className={styles.containLeft}>
              <MarkdownContent
                className={`homecontent`}
                content={convertTokens(home.frontmatter.aboutSection, metadata)}
              />
            </div>
            <div className={styles.containRight}>
              <MarkdownContent
                className={`homecontent`}
                content={convertTokens(
                  home.frontmatter.contentSection1,
                  metadata
                )}
              />
            </div>
          </div>
        </div>
      </section>

      <section id="row3-targ" className={`${styles.row} ${styles.row3}`}>
        <div className={styles.container}>
          <div id="feature-section-1" className={styles.flexWrapper}>
            <div className={styles.containLeft2}>
              <MarkdownContent
                className={`homecontent`}
                content={convertTokens(
                  home.frontmatter.contentSection2,
                  metadata
                )}
              />
            </div>
            <div className={styles.containMid2}>
              <div className={styles.featuresCircle}></div>
            </div>
            <div className={styles.containRight2}>
              <AnimatedGraphics
                id={styles.featureGraphic1}
                graphics={[
                  {
                    name: "Bulb_2",
                    id: styles.kyBulb2,
                    x: -50,
                    y: -50,
                  },
                  {
                    name: "Chart",
                    id: styles.kyChart,
                    x: 50,
                    y: 50,
                  },
                  {
                    name: "Gear_3",
                    id: styles.kyGear3,
                    x: -50,
                    y: 50,
                  },
                  {
                    name: "Paper",
                    id: styles.kyPaper,
                    x: -20,
                    y: 20,
                  },
                  {
                    name: "Question",
                    id: styles.kyQuestion,
                    x: -25,
                    y: 100,
                  },
                  {
                    name: "Text_2",
                    id: styles.kyText2,
                    x: 50,
                    y: -20,
                  },
                ]}
              />
            </div>
          </div>
          <div id="feature-section-2" className={styles.flexWrapper}>
            <div className={styles.containLeft3}>
              <AnimatedGraphics
                id={styles.featureGraphic2}
                graphics={[
                  {
                    name: "At_Symbol",
                    id: styles.eyrAtSymbol,
                    x: -10,
                    y: -50,
                  },
                  {
                    name: "Gear_2",
                    id: styles.eyrGear2,
                    x: 50,
                    y: 50,
                  },
                  {
                    name: "Money",
                    id: styles.eyrMoney,
                    x: 500,
                    y: 20,
                  },
                  {
                    name: "Quotes",
                    id: styles.eyrQuotes,
                    x: -20,
                    y: 20,
                  },
                  {
                    name: "Question",
                    id: styles.eyrQuestion,
                    x: 10,
                    y: 1000,
                  },
                  {
                    name: "eyrblock",
                    id: styles.eyrText,
                    x: 50,
                    y: 20,
                  },
                ]}
              />
            </div>
            <div className={styles.containMid3}>
              <div
                className={`${styles.featuresCircle} ${styles.featuresCircleRight}`}
              ></div>
            </div>
            <div className={styles.containRight3}>
              <MarkdownContent
                className={`homecontent`}
                content={convertTokens(
                  home.frontmatter.contentSection3,
                  metadata
                )}
              />
            </div>
          </div>
          <div id="feature-section-3" className={styles.flexWrapper}>
            <div className={styles.containLeft4}>
              <MarkdownContent
                className={`homecontent`}
                content={convertTokens(
                  home.frontmatter.contentSection4,
                  metadata
                )}
              />
            </div>
            <div className={styles.containMid4}>
              <div className={styles.featuresCircle}></div>
            </div>
            <div className={styles.containRight4}>
              <AnimatedGraphics
                id={styles.featureGraphic3}
                graphics={[
                  {
                    name: "sytlight",
                    id: styles.sytBulb,
                    x: -10,
                    y: -50,
                  },
                  {
                    name: "plane_1",
                    id: styles.sytPlane1,
                    x: 50,
                    y: 50,
                  },
                  {
                    name: "plane_2",
                    id: styles.sytPlane2,
                    x: 500,
                    y: 20,
                  },
                  {
                    name: "sytwheel",
                    id: styles.sytGear,
                    x: -20,
                    y: 20,
                  },
                  {
                    name: "Cone",
                    id: styles.sytCone,
                    x: 10,
                    y: 1000,
                  },
                  {
                    name: "Calendar",
                    id: styles.sytCalendar,
                    x: 50,
                    y: 20,
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </section>

      <section id="row4-section" className={`${styles.row} ${styles.row4}`}>
        <div className={styles.container}>
          <div className={styles.flexWrapper}>
            <div className={styles.containLeft5}>
              <MarkdownContent
                className={`homecontent`}
                content={convertTokens(
                  home.frontmatter.contentSection5,
                  metadata
                )}
              />
            </div>
            <div className={styles.containRight5}>
              <MarkdownContent
                className={`homecontent`}
                content={convertTokens(
                  home.frontmatter.contentSection6,
                  metadata
                )}
              />
            </div>
          </div>
        </div>
        <div id={styles.featureSection4} className={styles.flexWrapper}>
          <div className={styles.containMid5Left}>
            <AnimatedGraphics
              id={styles.featureGraphic4}
              graphics={[
                {
                  name: "fg4_rss",
                  id: styles.fg4_rss,
                  x: -30,
                  y: -50,
                },
                {
                  name: "fg4_750",
                  id: styles.fg4_750,
                  x: 50,
                  y: 50,
                },
                {
                  name: "fg4_email",
                  id: styles.fg4_email,
                  x: 100,
                  y: 30,
                },
                {
                  name: "fg4_video",
                  id: styles.fg4_video,
                  x: -30,
                  y: 30,
                },
                {
                  name: "fg4_354",
                  id: styles.fg4_354,
                  x: -50,
                  y: 100,
                },
                {
                  name: "fg4_star",
                  id: styles.fg4_star,
                  x: -50,
                  y: -80,
                },
                {
                  name: "fg4_calendar",
                  id: styles.fg4_calendar,
                  x: 50,
                  y: -20,
                },
                {
                  name: "fg4_music",
                  id: styles.fg4_music,
                  x: -50,
                  y: 50,
                },
                {
                  name: "fg4_smile",
                  id: styles.fg4_smile,
                  x: 50,
                  y: 50,
                },
                {
                  name: "fg4_money",
                  id: styles.fg4_money,
                  x: 50,
                  y: -70,
                },
                {
                  name: "fg4_alert",
                  id: styles.fg4_alert,
                  x: -50,
                  y: -90,
                },
                {
                  name: "fg4_user",
                  id: styles.fg4_user,
                  x: 50,
                  y: 40,
                },
              ]}
            />
          </div>
          <div className={styles.containMid5}>
            <MarkdownContent
              className={`homecontent`}
              content={convertTokens(
                home.frontmatter.contentSection7,
                metadata
              )}
            />
          </div>
        </div>
      </section>

      <section id="lets-connect" className={`${styles.row} ${styles.row5}`}>
        <div className={styles.container}>
          <div className={styles.flexWrapper}>
            <div className={styles.containLeft6}>
              <MarkdownContent
                className={`homecontent`}
                content={convertTokens(
                  home.frontmatter.contentSectionConnect,
                  metadata
                )}
              />
            </div>
            <div className={styles.containRight6}>
              <ContactForm selectedCta={selectedCta} />
            </div>
          </div>
        </div>
      </section>
      {/* <section
        id={styles.testimonials}
        className={`${styles.row} ${styles.row3}`}
      >
        <div className={styles.container}>
          <h2>What our customers are saying</h2>
          <TestimonialSlider />
        </div>
        <div class="btn-contain">
          <a id="row4-btn-3" class="row4-btns" href="#lets-connect" title="Yes, Sign Me Up">Leave us a review <span class="btn-arrow">></span></a>
        </div>
      </section> */}
    </Layout>
  )
}
export const query = graphql`
  query {
    home: markdownRemark(frontmatter: { title: { eq: "Home" } }) {
      id
      frontmatter {
        meta {
          title
          description
          page_visible
        }
        title
        template
        welcomeSection
        aboutSection
        contentSection1
        contentSection2
        contentSection3
        contentSection4
        contentSection5
        contentSection6
        contentSection7
        contentSectionConnect
        testimonials {
          name
          review
        }
      }
    }
  }
`
