import React from "react"
import { useSpring, animated } from "react-spring"
import styles from "@one-builder/gatsby-theme-ob-master/src/styles/global.module.scss"
import Image from "@one-builder/gatsby-theme-ob-master/src/components/common/image"

export default function AnimatedGraphics({ id, graphics }) {
  const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]
  const [props, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 },
  }))
  return (
    <div
      id={id}
      className={styles.featuresGraphic}
      onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}
      role="figure"
    >
      {graphics
        ? graphics.map(graphic => (
            <animated.div
              id={graphic.id}
              className={styles.featuresImg}
              style={{
                transform: props.xy.interpolate(
                  (x, y) =>
                    `translate3d(${x / graphic.x}px,${y / graphic.y}px,0)`
                ),
              }}
            >
              <Image filename={graphic.name.toLowerCase()} alt={graphic.name} />
            </animated.div>
          ))
        : null}
    </div>
  )
}
